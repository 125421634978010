// PropertyLinkForm.jsx
import { Formik } from 'formik';
import React from 'react';
import { Button, Container, Form } from 'react-bootstrap';
import * as Yup from 'yup';
import BannerHeader from '../../components/BannerHeader';
import AxiosRequest from '../../utils/AxiosRequest';
import { useNavigate } from 'react-router-dom';
// import './PropertyLinkForm.css';

const validationSchema = Yup.object().shape({
  platform: Yup.string().required('Platform selection is required'),
  propertyUrl: Yup.string().url('Please enter a valid URL').required('Property URL is required'),
});

const initialValues = {
  platform: '',
  propertyUrl: '',
};

const PropertyLinkForm = () => {
  const navigate = useNavigate();

  const handleSubmit = async (values, { setSubmitting, resetForm, setErrors }) => {
    try {
      const payload = {
        name: 'Luxury Villa',
        address: '789 Palm Beach Road, Miami',
        type: 'Villa',
        price: 1250000,
        size: {
          value: 5000,
          unit: 'sq feet',
        },
        transactionType: 'for sale',
        status: 'Ready to move',
        furnishedStatus: 'Furnished',
        descriptionLength: 250,
        AIWritingCreativity: 'Neutral',
        language: 'English (US)',
        user: '66e288e086ddf446ee27d379',
        priceSymbol: '$',
        houseUnit: 'sq feet',
        lat: 25.761681,
        lng: -80.191788,
        platform: values.platform,
        propertyUrl: values.propertyUrl,
      };

      const response = await AxiosRequest.post('/property/url', payload);
      if (response.status === 200) {
        navigate('/my-generation');
      }
      console.log('Success:', response);
      resetForm();
      // Add success notification here if needed
    } catch (error) {
      console.error('Error:', error);
      setErrors({ submit: 'Failed to submit form. Please try again.' });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <BannerHeader heading="Add Property Using Link" id={undefined} />
      <Container style={{ marginBottom: '-60px', marginTop: '30px' }}>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit} className="property-link-form">
              <div className="form-section">
                <Form.Group className="mb-4">
                  <Form.Label className="form-label" style={{ textTransform: 'uppercase' }}>
                    Choose Platform
                  </Form.Label>
                  <div className="platform-options">
                    <Form.Check
                      type="radio"
                      id="zillow"
                      name="platform"
                      value="zillow"
                      label="Zillow"
                      checked={values.platform === 'zillow'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="platform-radio"
                    />
                    <Form.Check
                      type="radio"
                      id="realtor"
                      name="platform"
                      value="realtor"
                      label="Realtor"
                      checked={values.platform === 'realtor'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="platform-radio"
                    />
                  </div>
                  {touched.platform && errors.platform && <div className="error-message">{errors.platform}</div>}
                </Form.Group>

                <Form.Group className="">
                  <Form.Label className="form-label-20">Enter property URL</Form.Label>
                  <Form.Control
                    type="text"
                    name="propertyUrl"
                    value={values.propertyUrl}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Enter property URL"
                    className={touched.propertyUrl && errors.propertyUrl ? 'is-invalid' : ''}
                  />
                  {touched.propertyUrl && errors.propertyUrl && (
                    <div className="error-message">{errors.propertyUrl}</div>
                  )}
                </Form.Group>

                {errors.submit && <div className="error-message mb-3">{errors.submit}</div>}

                <Button type="submit" disabled={isSubmitting} className="submit-button">
                  {isSubmitting ? 'Submitting...' : 'Submit'}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
};

export default PropertyLinkForm;
