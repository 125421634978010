import { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import WebLayout from "./Layout/WebLayout";
import PrivacyPolicy from "./PrivacyPolicy";
import Profile from "./Profile";
import AddPropertyLink from "./PropertyPage/AddPropertyLink";
import PPTForResidential from "./PropertyTemplate/PropertyTemplate";
import Template2 from "./PropertyTemplate/Template2";
import Template3 from "./PropertyTemplate/Template3";
import TermsConditions from "./TermsConditions";
const Spinner = lazy(() => import("../shared/Spinner"));
const LandingPage = lazy(() => import("./Homepage/LandingPage"));
const ForgotPassword = lazy(() => import("./User/forgetpassword"));
const ResetPassword = lazy(() => import("./User/resetpassword"));
const Pricing = lazy(() => import("./Price"));
const Login = lazy(() => import("./User/login"));
const SignUp = lazy(() => import("./User/signup"));
const AddProperty = lazy(() => import("./PropertyPage/AddProperty"));
const AddProperty_2 = lazy(() => import("./AddProperty_2/AddProperty_2"));
const Generation = lazy(() => import("./GenerationPage/Generation"));
const AllWorkspace = lazy(() => import("./workspace/AllWorkspace"));
const PropertyDetails = lazy(() =>
  import("./PropertyDetailsPage.js/PropertyDetails")
);
const PPTForCommercial = lazy(() =>
  import("./PropertyTemplate/NewCommercialTemplate")
);
const Faq = lazy(() => import("./Faq"));
const Contact = lazy(() => import("./Contact"));

const AppRoutes = () => {
  return (
    <Suspense fallback={<Spinner />}>
      <Routes>
        <Route exact path="/signup" element={<SignUp />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/invitations/workspaces/:workspaceId" element={<Login />} />
        <Route exact path="/" element={<LandingPage />} />
        <Route exact path="/forget-password" element={<ForgotPassword />} />
        <Route exact path="/reset-password" element={<ResetPassword />} />
        <Route exact path="/faq" element={<Faq />} />
        <Route exact path="/terms-and-conditions" element={<TermsConditions />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />


        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/pricing" element={<Pricing />} />
        <Route
          exact
          path="/property-template/:id"
          element={<PPTForResidential />}
        />
        <Route
          exact
          path="/commercial-template/:id"
          element={<PPTForCommercial />}
        />
        <Route element={<WebLayout />}>
          <Route exact path="/profile" element={<Profile />} />
          <Route exact path="/add-property" element={<AddProperty />} />
          <Route exact path="/add-property/link" element={<AddPropertyLink />} />
          <Route exact path="/add-property-2" element={<AddProperty_2 />} />
          <Route exact path="/my-generation" element={<Generation />} />
          <Route
            exact
            path="/property-details/:id"
            element={<PropertyDetails />}
          />
          {/* =========== */}
          <Route
            exact
            path="/template-2/:id"
            element={<Template2 />}
          />
          <Route
            exact
            path="/template-3/:id"
            element={<Template3 />}
          />
          {/* =========== */}
          <Route exact path="/all-workspaces" element={<AllWorkspace />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
