import React, { useState } from "react";
import PropertyLinkForm from "./PropertyLinkForm";

const AddPropertyLink = () => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <>
      {/* {isLoading && <Spinner />} */}
      <div className="add-property-standard-plan">
        <PropertyLinkForm isLoading={isLoading} setIsLoading={setIsLoading} />
      </div>
    </>
  );
};

export default AddPropertyLink;
